import { SvgProps } from './types'

const ArrowUpRight = ({ width = 24, height = 24, fill = 'currentColor' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path id="Vector 431 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M7.375 3.125L16.75 3.125C16.9158 3.125 17.0747 3.19085 17.1919 3.30806C17.3092 3.42527 17.375 3.58424 17.375 3.75V13.125C17.375 13.4702 17.0952 13.75 16.75 13.75C16.4048 13.75 16.125 13.4702 16.125 13.125V5.25888L4.69194 16.6919C4.44786 16.936 4.05214 16.936 3.80806 16.6919C3.56398 16.4479 3.56398 16.0521 3.80806 15.8081L15.2411 4.375L7.375 4.375C7.02982 4.375 6.75 4.09518 6.75 3.75C6.75 3.40482 7.02982 3.125 7.375 3.125Z" fill={fill} />
      </g>
    </svg>
  )
}

export default ArrowUpRight
