import { bignum } from '@metaplex-foundation/beet'
import {
  Side,
  toBN,
} from '@trathailoi/phoenix-orderbook-sdk'

import { isMainnet } from '@/constants/index'

export const defaultLimitOrderUrl = `/limit/${isMainnet ? 'reBTC_reUSD' : 'reVND_reUSD'}`

export const MAX_ORDERS = 20
export const fromOrderSequenceNumberToOrderSide = (orderSequenceNumber: bignum): Side => {
  const twosComplement = toBN(orderSequenceNumber).fromTwos(64)
  return twosComplement.isNeg() ? Side.Bid : Side.Ask
}

export const sortBySellingSide = (side: Side) => side === Side.Ask ? 1 : -1
