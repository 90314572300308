import { isInstantWireMode } from '@/constants/index'
import { IconName, staticImportIcon } from '@/utils/static-import-icon'

export interface SocialNetwork {
  iconAlt: string
  iconBg: string
  iconSrc: string
  name: string
  url: string
}

export const getSocialNetworkList = (): SocialNetwork[] => {
  return isInstantWireMode()
    ? [
        {
          iconAlt: 'facebook',
          iconBg: '#1F5F8D',
          iconSrc: staticImportIcon(IconName.FACEBOOK_WHITE),
          name: 'Facebook',
          url: 'https://www.facebook.com/instantwire',
        },
        {
          iconAlt: 'twitter',
          iconBg: '#1F5F8D',
          iconSrc: staticImportIcon(IconName.TWITTER_WHITE),
          name: 'Twitter',
          url: 'https://twitter.com/InstantWireInfo',
        },
        {
          iconAlt: 'facebook',
          iconBg: '#1F5F8D',
          iconSrc: staticImportIcon(IconName.TELEGRAM_WHITE),
          name: 'Telegram',
          url: 'https://t.me/InstantwireCommunity',
        },
      ]
    : [
        {
          iconAlt: 'discord',
          iconBg: '#21D969',
          iconSrc: staticImportIcon(IconName.DISCORD_BLACK),
          name: 'Telegram',
          url: 'https://discord.gg/JNvaFP5QCz',
        },
        {
          iconAlt: 'reddit',
          iconBg: '#21D969',
          iconSrc: staticImportIcon(IconName.REDDIT_BLACK),
          name: 'Reddit',
          url: 'https://www.reddit.com/r/renecblockchain/',
        },
        {
          iconAlt: 'twitter',
          iconBg: '#21D969',
          iconSrc: staticImportIcon(IconName.TWITTER_BLACK),
          name: 'Twitter',
          url: 'https://twitter.com/NemoSwapDEX',
        },
        {
          iconAlt: 'telegram',
          iconBg: '#21D969',
          iconSrc: staticImportIcon(IconName.TELEGRAM_BLACK),
          name: 'Telegram',
          url: 'https://t.me/NemoSwapGroup',
        },
      ]
}
