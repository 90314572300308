import { Stack } from '@mui/material'
import React from 'react'

import { useHeaderItems } from '@/hooks/use-header-nav'
import MenuLink from '@/layouts/components/MenuLink'

const MainMenuItems = () => {
  const { shownItems } = useHeaderItems()

  return (
    <>
      {shownItems.map((menu) => (
        <Stack key={menu.name}>
          <MenuLink
            href={menu.href}
            data-testid={menu.dataTestId}
            activeHref={menu.activeHref}>
            {menu.label}
          </MenuLink>
        </Stack>
      ))}
    </>
  )
}

export default MainMenuItems
