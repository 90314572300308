import NotificationsIcon from '@mui/icons-material/Notifications'
import TelegramIcon from '@mui/icons-material/Telegram'
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'

import useSubscribedTelegram from '@/hooks/use-subscribed-telegram'
import useTrans from '@/hooks/useTrans'
import { useWallet } from '@/wallet/adapter'

const SubscribeTelegramIcon = () => {
  const trans = useTrans()
  const { publicKey } = useWallet()
  const { data: subscribed, isLoading } = useSubscribedTelegram()

  if (!publicKey || isLoading || subscribed) return null

  return (
    <Tooltip
      leaveDelay={500}
      enterTouchDelay={0}
      placement="bottom-end"
      componentsProps={{
        tooltip: { className: 'bg-semiblack-300 mt-5 py-6 px-4 rounded-lg' },
      }}
      title={
        <Stack alignItems="center" className="w-min text-center">
          <Typography className="text-gray-100 text-xs pb-3">
            {trans.telegram_subscribe.message}
          </Typography>

          <Button
            className="bg-[#23B7EC] rounded-lg normal-case py-2.5 px-9"
            data-testid="subscribe-telegram-button"
            href={`https://t.me/nemoswap_bot?start=${publicKey}`}
            target="_blank"
          >
            <TelegramIcon className="mr-2" />
            <Typography className="text-sm whitespace-nowrap">
              {trans.telegram_subscribe.subscribe}
            </Typography>
          </Button>
        </Stack>
      }
    >
      <Box className="relative">
        <NotificationsIcon
          data-testid="subscribe-telegram-icon"
          className="w-8 h-8 hover:text-white iw:hover:text-[#000E0F] text-semiblack-600"
        />
        <Box className="rounded w-2 h-2 bg-[#EB5757] absolute top-1.5 right-1" />
      </Box>
    </Tooltip>
  )
}

export default SubscribeTelegramIcon
