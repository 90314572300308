import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { Stack, Tooltip, Typography } from '@mui/material'
import Image from 'next/image'
import React from 'react'

import Qrcode from '@/assets/svgs/download-wallet-app-qrcode.svg'
import { isInstantWireMode } from '@/constants/index'
import useTrans from '@/hooks/useTrans'

const DownloadWalletAppQrIcon = () => {
  const trans = useTrans()

  if (isInstantWireMode()) return null

  return (
    <Tooltip
      placement="bottom-end"
      componentsProps={{
        tooltip: { className: 'bg-semiblack-300 mt-5 py-6 px-4 rounded-lg' },
      }}
      title={
        <Stack alignItems="center" className="">
          <Image
            src={Qrcode}
            data-testid="qr-install-carax"
            alt="qr-install-carax"
            width={170}
            height={170} />
          <Typography className="text-gray-100 text-xs pt-3">
            {trans.scan_to_download_wallet_app}
          </Typography>
        </Stack>
      }>
      <QrCodeScannerIcon
        data-testid="show-qr-install-carax-icon"
        className="w-6 h-6 hover:text-white text-semiblack-600 mr-1"
      />
    </Tooltip>
  )
}

export default DownloadWalletAppQrIcon
