import {
  type ForwardedRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

type MutationListener = (mutations: MutationRecord[]) => void

const mutationListeners = (() => {
  const listeners: MutationListener[] = []

  try {
    const observer = new MutationObserver((mutations) => {
      for (const listener of listeners) {
        setTimeout(() => listener(mutations))
      }
    })
    observer.observe(document.body, { childList: true, subtree: true })
  } catch (err) {}

  return listeners
})()

const pushIfNotFound = <T>(arr: T[], value: T, newArrayIfChanged = false) => {
  const valueIndex = arr.indexOf(value)
  if (valueIndex === -1) {
    if (newArrayIfChanged) {
      arr = [...arr]
    }

    arr.push(value)
  }
  return arr
}

const spliceIfFound = <T>(arr: T[], value: T, newArrayIfChanged = false) => {
  const valueIndex = arr.indexOf(value)
  if (valueIndex !== -1) {
    if (newArrayIfChanged) {
      arr = [...arr]
    }

    arr.splice(valueIndex, 1)
  }
  return arr
}

/**
 * Creates a callback ref object and tracks when it gets added to or removed from
 * the DOM.
 */
const useObservedRef = <T extends HTMLElement>(forwardedRef?: ForwardedRef<T>) => {
  const [addListeners, setAddListeners] = useState([] as MutationListener[])
  const [removeListeners, setRemoveListeners] = useState([] as MutationListener[])

  const nodeRef = useRef<T | null>(null)
  const [lastValidNode, setLastValidNode] = useState(null as Node | null)

  useImperativeHandle(forwardedRef, () => nodeRef.current as T)

  const ref = useCallback((node: T | null) => {
    nodeRef.current = node
    if (node) {
      setLastValidNode(node)
    }
  }, [])

  const onAddedToDOM = useCallback((listener: (node: T) => void) => {
    const mutationListener: MutationListener = (mutations) => {
      if (
        mutations.filter((mutation) =>
          Array.from(mutation.addedNodes).some((node) => node.isEqualNode(nodeRef.current)),
        ).length
      ) {
        listener(nodeRef.current as T)
      }
    }

    setAddListeners((listeners) => {
      pushIfNotFound(mutationListeners, mutationListener)
      return pushIfNotFound(listeners, mutationListener, true)
    })

    return () => {
      setAddListeners((listeners) => {
        spliceIfFound(mutationListeners, mutationListener)
        return spliceIfFound(listeners, mutationListener, true)
      })
    }
  }, [])

  const onRemovedFromDOM = useCallback(
    (listener: (node: T) => void) => {
      const mutationListener: MutationListener = (mutations) => {
        if (
          mutations.filter((mutation) =>
            Array.from(mutation.removedNodes).some((node) => node.isEqualNode(lastValidNode)),
          ).length
        ) {
          listener(lastValidNode as T)
        }
      }

      setRemoveListeners((listeners) => {
        pushIfNotFound(mutationListeners, mutationListener)
        return pushIfNotFound(listeners, mutationListener, true)
      })

      return () => {
        setRemoveListeners((listeners) => {
          spliceIfFound(mutationListeners, mutationListener)
          return spliceIfFound(listeners, mutationListener, true)
        })
      }
    },
    [lastValidNode],
  )

  useEffect(() => {
    return () => {
      for (let idx = 0; idx < addListeners.length; ++idx) {
        spliceIfFound(mutationListeners, addListeners[idx])
      }

      for (let idx = 0; idx < removeListeners.length; ++idx) {
        spliceIfFound(mutationListeners, removeListeners[idx])
      }
    }
  }, [addListeners, removeListeners])

  return { ref, onAddedToDOM, onRemovedFromDOM }
}

export default useObservedRef
