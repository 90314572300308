import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { Container, IconButton,Stack } from '@mui/material'
import { useIntercom } from '@thanhvo102/react-use-intercom'
import Image from 'next/image'
import { memo } from 'react'
import Sticky from 'react-stickynode'

import { isInstantWireMode } from '@/constants/index'
import ConnectWallet from '@/layouts/MainLayout/components/ConnectWallet'
import HideOnScroll from '@/layouts/MainLayout/components/HideOnScroll'
import LanguageSwitcher from '@/layouts/MainLayout/components/LanguageSwitcher'

import MenuLink from '../../components/MenuLink'
import LeaderBoardHeader from '../components/LeaderBoardHeader'
import SubscribeTelegramIcon from '../components/SubscribeTelegramIcon'

const HeaderMobile = () => {
  const { show } = useIntercom()

  return (
    <Sticky className="z-50">
      <Stack>
        <LeaderBoardHeader />

        <HideOnScroll>
          <Container className="flex items-center bg-header">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="flex-wrap w-full h-full py-[5px]"
            >
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <MenuLink href="/" activeHref="none" data-testid="header-home-trade" className="!px-1">
                  <Image
                    src={(isInstantWireMode() ? '/instantwire' : '') + '/logo-mobile.svg'}
                    alt={isInstantWireMode() ? 'InstantWire' : 'NemoSwap'}
                    width="32"
                    height="32"
                    className="min-w-min"
                  />
                </MenuLink>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <ConnectWallet />
                <IconButton onClick={show} data-testid="mobile-intercom-launcher">
                  <SmsOutlinedIcon />
                </IconButton>
                <SubscribeTelegramIcon />
                <LanguageSwitcher />
              </Stack>
            </Stack>
          </Container>
        </HideOnScroll>
      </Stack>
    </Sticky>
  )
}

export default memo(HeaderMobile)
