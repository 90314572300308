const PendingTransactionsBadge = ({
  isDisplayPendingBadge,
  pendingTransactionCount,
}: {
  isDisplayPendingBadge: boolean
  pendingTransactionCount: number
}) => {
  return isDisplayPendingBadge ? (
    <span className="ml-[3px] inline-block h-4 min-w-[16px] bg-[#FF6701] rounded-full font-bold text-center text-xs pointer-events-none px-[3px]">
      {pendingTransactionCount}
    </span>
  ) : null
}

export default PendingTransactionsBadge
