import 'react-lazy-load-image-component/src/effects/blur.css'

import { Container, Stack } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'

import { Seo } from '@/components/base'

import Footer from './footer'
import Header from './header'
import NormalBackground from './NormalBackground'

type MainLayoutProps = PropsWithChildren & {
  hasNautilus?: boolean
  hasContainer?: boolean
  slot?: ReactNode
}

export const MainLayout = ({
  hasNautilus,
  hasContainer = true,
  children,
  slot,
}: MainLayoutProps) => {
  return <>
    <Seo />
    <Stack className="relative w-full min-h-screen overflow-hidden sm:h-screen bg-gradient-primary">
      <div className="absolute inset-0 bg-forest-bottom" />
      <Header />
      {slot}
      {hasNautilus && <NormalBackground />}
      <Stack className="pb-[100px] max-[767px]:pb-[80px] md:pb-0 z-10 flex-1 overflow-auto">
        {hasContainer ? <Container>{children}</Container> : children}
      </Stack>
      <Footer />
    </Stack>
  </>
}
