import { Box, IconButton, MenuItem } from '@mui/material'
import Image from 'next/image'
import { useCallback, useContext, useRef, useState } from 'react'

import LanguageIcon from '@/assets/svgs/world_icon.svg'
import Menu from '@/components/base/Menu'
import { EVENT_NAMES } from '@/constants/amplitude'
import useAmplitude from '@/hooks/use-amplitude'
import { LanguageContext } from '@/providers/language'

const COUNTRY_CODE_MAPPER = {
  vi: 'Tiếng Việt',
  en: 'English',
} as any

const LanguageSwitcher = () => {
  const amplitude = useAmplitude()
  const { lang, handleChangeLanguage } = useContext(LanguageContext)
  const [activeLang, setActiveLang] = useState(lang)

  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const [isOpenLangMenu, setIsOpenLangMenu] = useState(false)

  const handleClick = useCallback(() => {
    setIsOpenLangMenu(true)
  }, [])
  const handleClose = useCallback(() => {
    setIsOpenLangMenu(false)
  }, [])

  // const handleToggleTheme = useCallback(() => {
  //   toggleThemeMode()
  //   handleClose()
  // }, [toggleThemeMode, handleClose])

  const handleOnUnmounted = useCallback(() => {
    if (lang !== activeLang) {
      handleChangeLanguage(activeLang)
    }
  }, [activeLang, handleChangeLanguage, lang])

  return (
    <>
      <IconButton
        id="btn-preferences-settings"
        size="small"
        color="inherit"
        aria-controls={isOpenLangMenu ? 'menu-preferences-settings' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpenLangMenu ? 'true' : undefined}
        onClick={handleClick}
        ref={anchorEl}
        data-testid="LanguageIcon"
      >
        <Image src={LanguageIcon} alt="language-icon" width={30} />
      </IconButton>
      <Menu
        id="menu-preferences-settings"
        anchorEl={anchorEl.current}
        open={isOpenLangMenu}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'btn-preferences-settings',
        }}
        onUnmounted={handleOnUnmounted}
      >
        {Object.keys(COUNTRY_CODE_MAPPER).map((code) => (
          <MenuItem
            key={code}
            selected={lang === code}
            onClick={() => {
              handleClose()
              setActiveLang(code)
              amplitude.track(EVENT_NAMES.page_select_language, { lang: code })
            }}
            data-testid={`btn-change-language-to-${code}`}
          >
            <Box className="w-[40px]">
              <Image src={`/lang-${code}-icon.webp`} alt={code} width={32} height={22} />
            </Box>
            {COUNTRY_CODE_MAPPER[code]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSwitcher
