import { Box } from '@mui/material'
import { MouseParallax } from 'react-just-parallax'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const NormalBackground = () => {
  return (
    <>
      <MouseParallax isAbsolutelyPositioned strength={0.01}>
        <Box
          className="fixed -left-[120px] sm:-left-[130px] top-[40vh] sm:top-[170px] w-[400px] sm:w-[664px]"
          data-testid="coin-bubble-left"
        >
          <LazyLoadImage
            src="/ship.webp"
            placeholderSrc="/ship_placeholder.webp"
            alt="ship"
            effect="blur"
            width="100%"
            height="100%"
          />
        </Box>
      </MouseParallax>
    </>
  )
}

export default NormalBackground
