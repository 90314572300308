import { useIsMobile } from '@/utils/use-is-mobile'

import FooterDesktop from './footer.desktop'
import FooterMobile from './footer.mobile'

const Footer = () => {
  const isMobile = useIsMobile()
  return isMobile ? <FooterMobile /> : <FooterDesktop />
}

export default Footer
