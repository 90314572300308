import CloseIcon from '@mui/icons-material/Close'
import { Box, Collapse, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import ArrowUpRight from '@/components/base/icons/ArrowUpRight'
import { isInstantWireMode } from '@/constants/index'
import useAmplitude, { EVENT_NAMES } from '@/hooks/use-amplitude'
import useFeatureFlag from '@/hooks/use-feature-flag'
import useLocalStorage from '@/hooks/useLocalStorage'
import useTrans from '@/hooks/useTrans'

const LeaderBoardHeader = () => {
  const router = useRouter()
  const amplitude = useAmplitude()
  const trans = useTrans()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [
    isBannerClosedByUser,
    setIsBannerClosedByUser,
  ] = useLocalStorage('p2p_leader_board_banner_hidden', false)
  const { enabled: isFeatureEnabled } = useFeatureFlag('leader_board_feature_enabled')
  const isAlreadyOnLeaderBoardPage = router.pathname.includes('leader-board')
  const showHeader = isInstantWireMode() && isFeatureEnabled && !isAlreadyOnLeaderBoardPage && !isBannerClosedByUser

  const handleClick = useCallback(() => {
    amplitude.track(EVENT_NAMES.leader_board_top_banner)
    router.push('/p2p/leader-board')
  }, [router, amplitude])

  return (
    <Collapse in={showHeader}>
      <Stack
        data-testid="leader-board-header"
        direction="row"
        className="iw:bg-[#1F5F8D] p-2 w-full bg-transparent"
        alignItems="center"
      >
        <Stack flexGrow={1} alignItems="center">
          <Stack
            data-testid="leader-board-header-btn"
            direction="row"
            className="flex-grow cursor-pointer text-white hover:underline"
            justifyContent="center"
            alignItems="center"
            onClick={handleClick}
          >
            <Image alt="sale-icon" src="/lightning-speaker.png" height={26} width={32} />
            <Typography className="text-xs ml-3">{trans.p2p_leader_board_text}</Typography>
            <Box className="ml-1">
              <ArrowUpRight
                height={isDesktop ? 20 : 16}
                width={isDesktop ? 20 : 16}
                fill={isInstantWireMode() ? '#F79009' : '#FF6701'}
              />
            </Box>
          </Stack>
        </Stack>

        <CloseIcon
          data-testid="leader-board-header-close-btn"
          className="text-white cursor-pointer opacity-80 hover:opacity-100 ml-auto"
          fontSize="small"
          onClick={() => setIsBannerClosedByUser(true)}
        />
      </Stack>
    </Collapse>
  )
}

export default LeaderBoardHeader
