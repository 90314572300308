import { api } from '.'

export const checkSubscribedTelegram = async (
  walletAddress?: string,
): Promise<boolean> => {
  const { data } = await api.get<boolean>('telegram_subscribers/subscribed', {
    params: {
      wallet_address: walletAddress,
    },
  })

  return data
}
