import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Link from '@mui/material/Link'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import React, { useContext, useMemo, useRef, useState } from 'react'

import { isInstantWireMode } from '@/constants/index'
import { PendingTransactionsCtx } from '@/contexts/pending-transactions'
import { useHeaderItems } from '@/hooks/use-header-nav'
import useTrans from '@/hooks/useTrans'
import ExtendedMenuItems
  from '@/layouts/MainLayout/components/ExtendedMenuItems'
import MainMenuItems from '@/layouts/MainLayout/components/MainMenuItems'
import { isActiveMenuLink,LINK_EXPANDED_MENU_ITEMS } from '@/utils/helpers'

import { getSocialNetworkList } from './helpers'

const FooterMobile = () => {
  const trans = useTrans()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const ref = useRef()
  const open = Boolean(anchorEl)
  const { moreItems } = useHeaderItems()
  const { isDisplayPendingBadge, pendingTxCountByTrades } = useContext(PendingTransactionsCtx)

  const pathname = usePathname()
  const isActive = useMemo(() => {
    return isActiveMenuLink(pathname || '', LINK_EXPANDED_MENU_ITEMS)
  }, [pathname])

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : ref.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box className="w-full fixed z-10 bottom-[8px]" ref={ref}>
      <Container>
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="bg-[#121823] rounded-[28px] p-2 iw:bg-[#DFF1FF]"
            style={{
              boxShadow: isInstantWireMode() ? '0px 2px 4px 0px #0000001A' : '',
            }}
            spacing={2}
          >
            <MainMenuItems />
            {moreItems.length > 0 && (
              <Stack>
                <Box className="relative">
                  {isDisplayPendingBadge && (
                    <span
                      data-testid="pending-tx-count-mobile"
                      className="absolute h-4 min-w-[16px] bg-[#FF6701] rounded-full font-bold text-center text-xs pointer-events-none px-[3px] top-[-8px] left-[50%] translate-x-[-50%] text-white"
                    >
                      {pendingTxCountByTrades}
                    </span>
                  )}
                  <MoreHorizIcon
                    data-testid="btn-extra-menu"
                    onClick={handleClick}
                    className={classNames(
                      'sm:block cursor-pointer opacity-80 hover:opacity-100',
                      open && 'opacity-100',
                      isActive && 'rounded-[100px] bg-[#1E2735] iw:bg-white',
                    )}
                  />
                </Box>
              </Stack>
            )}
            <Popper open={open} anchorEl={ref.current} placement="top" className="z-10 w-full px-4">
              <Box className="bg-[#1E2735] iw:bg-white rounded-[16px] border border-[#61667E] p-2">
                <ExtendedMenuItems handleClose={handleClose} />
                <Stack
                  data-testid="social-links-wrapper"
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={3}
                  className="px-4 py-4"
                >
                  {getSocialNetworkList().map((value, index) => (
                    <Tooltip
                      key={index}
                      title={value.name}
                      placement="top"
                      TransitionComponent={Fade}
                    >
                      <Link data-testid={`social-link-${value.iconAlt}`} href={value.url} target="_blank" rel="noreferrer">
                        <div className="w-6 h-6 p-1 rounded-full" style={{ background: value.iconBg }}>
                          <Image src={value.iconSrc} alt={value.iconAlt} width={16} />
                        </div>
                      </Link>
                    </Tooltip>
                  ))}
                </Stack>
                <Stack className="px-2 py-2">
                  <Divider />
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  className="py-2"
                >
                  <Typography
                    data-testid="footer-copyright"
                    variant="body2"
                    color="textSecondary"
                    textAlign="center"
                  >
                    © {new Date().getFullYear()} {isInstantWireMode() ? 'Instantwire' : 'Renec Foundation'}. All rights reserved.
                  </Typography>
                  <Link href="/terms-and-conditions" variant="body2" color="textSecondary">
                    {trans.terms_of_use}
                  </Link>
                </Stack>
              </Box>
            </Popper>
          </Stack>
        </ClickAwayListener>
      </Container>
    </Box>
  )
}

export default FooterMobile
