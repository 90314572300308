import Head from 'next/head'
import React from 'react'

import { isInstantWireMode } from '@/constants/index'
import useTrans from '@/hooks/useTrans'

export interface SeoData {
  title?: string;
  description?: string;
  url?: string;
  thumbnailUrl?: string;
  keywords?: string;
}

export interface SeoProps {
  data?: SeoData;
}

const Seo = ({ data }: SeoProps) => {
  const trans = useTrans()
  const defaultData: SeoData = trans.meta[isInstantWireMode() ? 'instantwire' : 'home']
  const { title, description, url, thumbnailUrl, keywords } = { ...defaultData, ...data }

  return (
    <Head>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {keywords ? (<meta name="keywords" content={keywords} />) : null}

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={thumbnailUrl} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={thumbnailUrl} />
    </Head>
  )
}

export default Seo
