import {
  Box,
  Container,
  Fade,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Image from 'next/image'
import { memo } from 'react'

import { isInstantWireMode } from '@/constants/index'
import useTrans from '@/hooks/useTrans'

import { getSocialNetworkList } from './helpers'

const FooterDesktop = () => {
  const trans = useTrans()

  return (
    <Box className="w-full bg-black/40 iw:bg-white py-4 relative z-10">
      <Container>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          className="w-full flex-col sm:flex-row"
        >
          <Stack direction={{ sm: 'column', md: 'row' }} justifyContent="center" alignItems="center" spacing={2}>
            <Typography data-testid="footer-copyright" variant="body2" color="textSecondary">
              © {new Date().getFullYear()} {isInstantWireMode() ? 'Instantwire' : 'Renec Foundation'}. All rights reserved.
            </Typography>
            <Link href="/terms-and-conditions" variant="body2" color="textSecondary">{trans.terms_of_use}</Link>
          </Stack>

          <Stack
            data-testid="social-links-wrapper"
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
            className="mt-3 sm:mt-0"
          >
            {getSocialNetworkList().map((value, index) => (
              <Tooltip
                key={index}
                title={value.name}
                placement="top"
                TransitionComponent={Fade}
              >
                <Link data-testid={`social-link-${value.iconAlt}`} href={value.url} target="_blank" rel="noreferrer">
                  <div className="w-6 h-6 p-1.5 rounded-full" style={{ background: value.iconBg }}>
                    <Image src={value.iconSrc} alt={value.iconAlt} width={12} />
                  </div>
                </Link>
              </Tooltip>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default memo(FooterDesktop)
