import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu'
import { forwardRef, useEffect } from 'react'

import useObservedRef from '@/hooks/use-observed-ref'

export interface MenuProps extends MuiMenuProps {
  /**
   * Called *after* the menu element has been added to DOM.
   */
  onMounted?: (node: HTMLDivElement) => void

  /**
   * Called *after* the menu element has been removed from DOM.
   */
  onUnmounted?: (node: HTMLDivElement) => void
}

/**
 * Wrapper of the {@link Menu} component that provides callbacks related to DOM
 * mutations.
 */
// eslint-disable-next-line react/display-name
const Menu = forwardRef<HTMLDivElement, MenuProps>(({ onMounted, onUnmounted, ...props }, ref) => {
  const { ref: menuRef, onAddedToDOM, onRemovedFromDOM } = useObservedRef(ref)

  useEffect(() => {
    onAddedToDOM((node) => onMounted?.(node))
  }, [onAddedToDOM, onMounted])

  useEffect(() => {
    onRemovedFromDOM((node) => onUnmounted?.(node))
  }, [onRemovedFromDOM, onUnmounted])

  return <MuiMenu ref={menuRef} {...props} />
})

Menu.displayName = 'Menu'
export default Menu
