import MenuItem from '@mui/material/MenuItem'
import React, { memo } from 'react'

import { useHeaderItems } from '@/hooks/use-header-nav'
import MenuLink from '@/layouts/components/MenuLink'

const ExtendedMenuItems = ({ handleClose }: { handleClose: () => void }) => {
  const { moreItems } = useHeaderItems()

  return (
    <>
      {moreItems.map((menu) => (
          <MenuItem
            key={menu.name}
            onClick={handleClose}
            disableRipple
            className="p-0 items-stretch justify-items-stretch"
            data-testid={menu.dataTestId}
          >
            <MenuLink
              className="flex items-center py-[6px] px-[16px] w-full"
              activeHref={menu.activeHref}
              href={menu.href}
              data-testid={`header-nav-${menu.name}`}
            >
              {menu.label}
              {menu.badge}
            </MenuLink>
          </MenuItem>
      ))}
    </>
  )
}

export default memo(ExtendedMenuItems)
