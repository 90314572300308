import { Link, LinkProps } from '@mui/material'
import classNames from 'classnames'
import NextLink from 'next/link'
import { usePathname } from 'next/navigation'
import React, { useMemo } from 'react'

import useAmplitude, { EVENT_NAMES } from '@/hooks/use-amplitude'
import { isActiveMenuLink } from '@/utils/helpers'
import { useIsMobile } from '@/utils/use-is-mobile'

interface IMenuLinkProps extends LinkProps {
  activeHref?: string | string[]
}

const MenuLink: React.FC<IMenuLinkProps> = ({ activeHref, ...props }) => {
  const amplitude = useAmplitude()
  const pathname = usePathname()
  const isMobile = useIsMobile()

  const selectedActiveHref = activeHref || props.href
  const isActive = useMemo(() => {
    const currentPath = pathname || ''
    const activePaths = selectedActiveHref instanceof Array ? selectedActiveHref : [selectedActiveHref || '']
    return isActiveMenuLink(currentPath, activePaths)
  }, [pathname, selectedActiveHref])

  return (
    <Link
      color="secondary"
      component={NextLink}
      onClick={() => {
        amplitude.track(EVENT_NAMES.page_select_header_menu, {
          // remove first slash and replace other slashes as dashes
          menu_name: props.href?.replace(/^\//, '').replace(/\//g, '-') || 'home',
        })
      }}
      {...props}
      className={classNames(
        'menu-link text-sm no-underline sm:text-base whitespace-nowrap hover:opacity-80 px-4 py-2',
        props.className, {
          'text-[#C2CBD9] iw:text-[#666666]': !isActive,
          'font-bold iw:text-[#1F5F8D] menu-link--active': isActive,
          'text-[#FF6701]': isActive && !isMobile,
          'rounded-[100px] bg-[#1E2735] iw:bg-white': isActive && isMobile,
        })
      }
    />
  )
}

export default MenuLink
