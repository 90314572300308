import { Stack } from '@mui/material'
import { useContext } from 'react'

import { WalletBalancesContext } from '@/providers/wallet-balances'

const WalletBalances = () => {
  const { userBalances } = useContext(WalletBalancesContext)

  return (
    <Stack display='none'>
      {
        Object.keys(userBalances).map((tokenSymbol) => {
          return (
            <Stack
              key={tokenSymbol}
              data-testid={`wallet-has-${tokenSymbol}`}
              data-amount={userBalances[tokenSymbol]}>
            </Stack>
          )
        })
      }
    </Stack>
  )
}

export default WalletBalances
