import { Container, Stack } from '@mui/material'
import Image from 'next/image'
import React, { memo } from 'react'
import Sticky from 'react-stickynode'

import { isInstantWireMode } from '@/constants/index'
import ConnectWallet from '@/layouts/MainLayout/components/ConnectWallet'
import DownloadWalletAppQrIcon from '@/layouts/MainLayout/components/DownloadWalletAppQrIcon'
import LanguageSwitcher from '@/layouts/MainLayout/components/LanguageSwitcher'
import MainMenuItems from '@/layouts/MainLayout/components/MainMenuItems'

import ExtraMenuItems from '../../components/ExtraMenuItems'
import MenuLink from '../../components/MenuLink'
import LeaderBoardHeader from '../components/LeaderBoardHeader'
import SubscribeTelegramIcon from '../components/SubscribeTelegramIcon'

const HeaderDesktop = () => {
  return (
    <Sticky className="z-50">
      <Stack className="bg-header">
        <LeaderBoardHeader />

        <Container className="flex items-center">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="flex-wrap w-full h-full py-[5px]"
          >
            <Stack
              direction="row"
              alignItems="center"
              className="justify-between w-full sm:w-fit sm:justify-start"
            >
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                className="mr-4 sm:mr-12"
                flexShrink={1}
              >
                <MenuLink href="/" data-testid="header-home-trade" className="!px-0">
                  <Image
                    src={(isInstantWireMode() ? '/instantwire' : '') + '/logo.svg'}
                    alt={isInstantWireMode() ? 'InstantWire' : 'NemoSwap'}
                    width="176"
                    height="32"
                    className="min-w-min"
                  />
                </MenuLink>
              </Stack>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={{ sm: 5, xs: 2 }}
              >
                <MainMenuItems />
                <Stack className="py-1">
                  <ExtraMenuItems />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              className="w-full mt-2 sm:w-fit sm:mt-0"
            >
              <ConnectWallet />
              <DownloadWalletAppQrIcon />
              <SubscribeTelegramIcon />
              <LanguageSwitcher />
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Sticky>
  )
}

export default memo(HeaderDesktop)
