import { useQuery } from 'react-query'

import { checkSubscribedTelegram } from '@/utils/apis/telegram-subscribers'
import { useWallet } from '@/wallet/adapter'

const useSubscribedTelegram = () => {
  const { publicKey } = useWallet()

  return useQuery(
    ['subscribedTelegram', publicKey?.toBase58()],
    () => checkSubscribedTelegram(publicKey?.toBase58()),
    { refetchOnWindowFocus: false, enabled: !!publicKey},
  )
}

export default useSubscribedTelegram
