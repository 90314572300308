import { useIsMobile } from '@/utils/use-is-mobile'

import HeaderDesktop from './header.desktop'
import HeaderMobile from './header.mobile'

const Header = () => {
  const isMobile = useIsMobile()
  return isMobile ? <HeaderMobile /> : <HeaderDesktop />
}

export default Header
