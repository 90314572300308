import arrayPartition from 'lodash/partition'
import { useContext, useMemo } from 'react'

import { defaultLimitOrderUrl } from '@/components/limit-order/helpers'
import { isInstantWireMode } from '@/constants/index'
import { PendingTransactionsCtx } from '@/contexts/pending-transactions'
import PendingTransactionsBadge from '@/hooks/use-header-nav/pending-transactions-badge'
import { useSettings } from '@/hooks/use-settings'
import useTrans from '@/hooks/useTrans'

export const useHeaderItems = () => {
  const trans = useTrans()
  const { data: settingsData, isLoading: isLoadingSettings } = useSettings()
  const { isDisplayPendingBadge, pendingTxCountByTrades } = useContext(PendingTransactionsCtx)

  const menus = useMemo(() => {
    const isInstantWire = isInstantWireMode()
    const isNemo = !isInstantWire
    return [
      {
        name: 'trade',
        label: trans.header.trade,
        activeHref: '/swap',
        href: '/swap',
        enabled: isNemo,
        badge: null,
        dataTestId: 'header-trade',
      },
      {
        name: 'p2p-market',
        label: trans.header.p2p_market,
        activeHref: ['/', '/p2p'],
        href: '/p2p',
        enabled: !isLoadingSettings && settingsData?.p2p_market_feature_enabled,
        badge: null,
        dataTestId: 'header-nav-p2p-market',
      },
      {
        name: 'assets',
        label: trans.header.assets,
        activeHref: '/assets',
        href: '/assets',
        enabled: isNemo,
        badge: null,
        dataTestId: 'header-menu-assets',
      },
      {
        name: 'limit',
        label: trans.header.limit,
        activeHref: '/limit',
        href: defaultLimitOrderUrl,
        enabled: isNemo && !isLoadingSettings && settingsData?.orderbook_feature_enabled,
        badge: null,
        dataTestId: 'header-nav-limit',
      },
      {
        name: 'liquidity',
        label: trans.header.liquidity,
        activeHref: '/liquidity',
        href: '/liquidity/browse',
        enabled: isNemo,
        badge: null,
        dataTestId: 'header-menu-liquidity',
      },
      {
        name: 'history',
        label: trans.header.transactions,
        activeHref: '/history',
        href: '/history',
        enabled: true,
        badge: (
          <PendingTransactionsBadge
            isDisplayPendingBadge={isDisplayPendingBadge}
            pendingTransactionCount={pendingTxCountByTrades}
          />
        ),
        dataTestId: 'header-menu-history',
      },
    ]
  }, [trans, settingsData, isDisplayPendingBadge, pendingTxCountByTrades, isLoadingSettings])

  const [shownItems, moreItems] = useMemo(() => {
    const enabledMenus = menus.filter((item) => item.enabled)
    return arrayPartition(enabledMenus, (item) => enabledMenus.indexOf(item) < 3)
  }, [menus])

  return {
    menus,
    shownItems,
    moreItems,
  }
}
