import MenuIcon from '@mui/icons-material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Menu, Stack } from '@mui/material'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'
import { useContext, useMemo,useRef, useState } from 'react'
import { memo, useCallback } from 'react'

import { PendingTransactionsCtx } from '@/contexts/pending-transactions'
import { useHeaderItems } from '@/hooks/use-header-nav'
import { isActiveMenuLink,LINK_EXPANDED_MENU_ITEMS } from '@/utils/helpers'

import ExtendedMenuItems from '../MainLayout/components/ExtendedMenuItems'

const ExtraMenuItems = () => {
  const anchorEl = useRef<Element | null>(null)
  const { moreItems } = useHeaderItems()
  const { isDisplayPendingBadge, pendingTxCountByTrades } = useContext(PendingTransactionsCtx)
  const [isOpenMoreMenu, setIsOpenMoreMenu] = useState(false)

  const pathname = usePathname()
  const isActive = useMemo(() => {
    return isActiveMenuLink(pathname || '', LINK_EXPANDED_MENU_ITEMS)
  }, [pathname])

  const handleClick = useCallback(() => {
    setIsOpenMoreMenu(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpenMoreMenu(false)
  }, [])

  if (!moreItems.length) return null

  return (
    <Stack>
      <Stack
        ref={anchorEl}
        id="menu-more-options"
        onClick={handleClick}
        data-testid="btn-extra-menu"
      >
        <Box className="relative">
          {isDisplayPendingBadge && (
            <span className="absolute h-4 min-w-[16px] bg-[#FF6701] rounded-full font-bold text-center text-xs pointer-events-none px-[3px] top-[-8px] left-[50%] translate-x-[-50%]">
              {pendingTxCountByTrades}
            </span>
          )}
          <MoreHorizIcon
            className={classNames(
              'hidden sm:block cursor-pointer opacity-80 hover:opacity-100',
              isOpenMoreMenu && 'opacity-100',
              isActive && 'text-[#FF6701]',
            )}
          />
        </Box>
        <MenuIcon
          className={classNames(
            'block sm:hidden cursor-pointer opacity-80 hover:opacity-100',
            isOpenMoreMenu && 'opacity-100',
            isActive && 'text-[#FF6701]',
          )}
        />
      </Stack>
      <Menu
        id="menu-items-more-options"
        anchorEl={anchorEl.current}
        open={isOpenMoreMenu}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'menu-more-options',
        }}
      >
        <ExtendedMenuItems handleClose={handleClose} />
      </Menu>
    </Stack>
  )
}

export default memo(ExtraMenuItems)
