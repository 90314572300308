import { Stack } from '@mui/material'
import dynamic from 'next/dynamic'

import { EVENT_NAMES } from '@/constants/amplitude'
import useAmplitude from '@/hooks/use-amplitude'
import { useWallet } from '@/wallet/adapter'
import WalletBalances from '@/wallet/ui/WalletBalances'

const WalletMultiButtonDynamic = dynamic(
  () => import('@/wallet/ui/WalletMultiButton'),
  { ssr: false },
)

const ConnectWallet = () => {
  const amplitude = useAmplitude()
  const { publicKey } = useWallet()

  return (
    <Stack
      onClick={() => {
        if (!publicKey) {
          amplitude.track(EVENT_NAMES.page_connect_wallet_on_header)
        }
      }}
      data-connected={!!publicKey}
    >
      <WalletMultiButtonDynamic />
      <WalletBalances />
    </Stack>
  )
}

export default ConnectWallet
